import React from "react";
import {
  IoPhonePortrait,
  IoPeople,
  IoPricetag,
  IoPaperPlane,
  IoFlash,
  IoCheckboxOutline,
  IoSettings,
  IoHappy,
  IoConstruct,
  IoHeadset,
  IoPin,
  IoDisc,
  IoCheckmark,
  IoCalculator,
  IoPerson,
} from "react-icons/io5";
import { Link } from "gatsby";
import { useModal } from "../components/useModal";

import Image from "../components/image";
import {
  CallToAction,
  Feature,
  ImageGraphic,
  Modal,
  CustomerQuote,
} from "../components/site";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Trades = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  const industry_benefits = [
    "No more calculations, paper timesheets or manual data entry",
    "Increase payroll accuracy with on-site GPS time tracking",
    "Easily manage expense claims & reimbursements",
    "Understand the profitability of each job with detailed timesheets",
  ];

  return (
    <Layout>
      <Seo
        title="Payroll Software for Trades"
        description="On-site GPS time tracking and apprentice, tools, milage & meal allowance payments in one easy online tool. PayHero payroll makes it easy. Get started today."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={70} noPaddingBottom>
          <Row stackGap={80} alignCenter>
            <Box size={50} stackGap={50} centerOnMobile>
              <Box stackGap={20}>
                <h1 className="-lg -primary badge">Payroll for Tradies</h1>
                <h2 className="h1 -fontLarge">
                  Don't waste your evenings on admin
                </h2>
                <Box stackGap={15}>
                  {industry_benefits.map((item, i) => {
                    return (
                      <Row
                        alignCenter
                        noBreak
                        stackGap={10}
                        justify="flex-start"
                        key={i}
                        css={{ fontSize: "1.2rem", textAlign: "left" }}
                        centerOnMobile
                      >
                        <IoCheckmark
                          css={{
                            fontSize: "1.8rem",
                            color: "#5eb22e",
                            flex: "0 0 auto",
                          }}
                        />
                        <span>{item}</span>
                      </Row>
                    );
                  })}
                </Box>
              </Box>
              <Box stackGap={15}>
                <FlexButtons>
                  <Button className="primary -lg gtm-cta" to="/signup">
                    Try 14 Days Free
                  </Button>
                  <Button
                    className="grey -lg gtm-cta"
                    onClick={toggleModal}
                    atag
                  >
                    Book a Sales Call
                  </Button>
                </FlexButtons>
                <p css={{ color: "#999" }}>
                  Get started in minutes, no credit card required.
                </p>
              </Box>
            </Box>
            <Box size={50} css={{ padding: "40px" }}>
              <ImageGraphic variant="1" />
              <Image
                filename="TradesHero.jpg"
                alt="Payroll for Trades | PayHero"
                centerImage
                addShadow
                rounded
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="Thanks to PayHero I process our payroll much faster. Not only has the business saved time using PayHero’s time capture tools, but we also have confidence that PayHero keeps up with the latest payroll legislation."
            name="Michele Howley"
            company="PR Construction"
            pic="PRConstruction_Circle.png"
            center
            large
          />
          <hr />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={150}>
          <Row stackGap={70} alignCenter mobileReverse>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Timesheets, leave, expenses & payslips on mobile"
                filename="PayHero_Mobile_Tradie.png"
                maxWidth={500}
                centerImage
                addShadow
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Employee mobile app
                </h2>
                <h4 className="h2">
                  GPS time tracking, expenses, leave and payslips in one simple
                  employee app
                </h4>
              </Box>
              <Feature
                icon={<IoPin key={0} />}
                title="Timesheets in their pocket"
                description="While on-site, employees can submit timesheets against different jobs or work items by entering start, end and break times or using the GPS start/stop timer. They can access their old payslips too, so you don’t need to dig them out."
              />
              <Feature
                icon={<IoPhonePortrait key={0} />}
                title="Foreman clock-in"
                description="Simplify the timesheet process by getting site managers to clock employees in & out of work sites on their phone."
              />
              <Feature
                icon={<IoPricetag key={0} />}
                title="Capture expenses with ease"
                description="Expense claims couldn’t be easier. Your employees can take a photo of a receipt, categorise it and submit the expense for approval from their phone."
              />
              <Feature
                icon={<IoPaperPlane key={0} />}
                title="Easy leave requests"
                description="Employees can submit leave requests, check the approval status and balances and see a record of leave already taken and booked in with a simple calendar view."
              />
            </Box>
          </Row>
          <Row stackGap={100} alignCenter>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Stress-free payroll
                </h2>
                <h4 className="h2">
                  Pay employees right with payroll software, built for
                  businesses like yours
                </h4>
              </Box>
              <Feature
                icon={<IoConstruct key={0} />}
                title="Tool allowances and more"
                description="It’s simple to manage tradie-specific payroll needs in PayHero. Our support team can help you set up automated tool allowances and reimbursements for mileage or meals."
              />
              <Feature
                icon={<IoPeople key={0} />}
                title="Easy job costing"
                description="With the PayHero employee app your staff can allocate hours against different locations and tasks. That means you can easily see the profitability of each job, get your invoicing spot on and apply wage costs to the right cost centre in Xero."
              />
              <Feature
                icon={<IoPaperPlane key={0} />}
                title="Integrate with Xero"
                description="Automatically send payroll data through to Xero. Keep it simple or assign wage costs to different account codes and tracking categories for a more detailed breakdown."
              />
            </Box>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Sync timesheets from mobile directly to payroll"
                filename="PayHero_Review_Tradie.png"
                addShadow
                centerImage
                rounded
              />
            </Box>
          </Row>
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                filename="PayrollForTrades_PayHero.png"
                alt="Payroll software compliance for Tradies"
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Simplify compliance
                </h2>
                <h4 className="h2">
                  Look after your bottom line and let PayHero take care of the
                  tricky payroll stuff
                </h4>
              </Box>
              <Feature
                icon={<IoPerson key={0} />}
                title="Employee self-service"
                description="Save time and hassle by letting your staff view leave balances and request leave from the employee app. They can access their old payslips too, so you don’t need to dig them out."
              />
              <Feature
                icon={<IoCheckboxOutline key={0} />}
                title="Stress-free payroll compliance"
                description="Rest easy knowing leave and holiday entitlements are calculated to match the latest MBIE guidelines, and displayed transparently to employers and employees."
              />
              <Feature
                icon={<IoFlash key={0} />}
                title="Automated payday filing"
                description={[
                  <Link to="/payday-filing">Payday filing</Link>,
                  <span>
                    {" "}
                    is a piece of cake in PayHero – just connect your account to
                    IRD and we’ll automatically send your payroll information
                    through after every pay.
                  </span>,
                ]}
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <Box className="-textCenter">
            <h2>PayHero makes trades payroll easy</h2>
            <h4>
              Look after your staff, get their entitlements right and understand
              your wage costs. <br className="hideOnMobile" />
              PayHero is the number one online payroll and timesheet system for
              New Zealand tradies.
            </h4>
          </Box>
          <Box stackGap={40}>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoPeople />
                  <br />
                  Pay your staff in minutes
                </h3>
                <p>
                  Time entries, leave & expenses submitted by your employees
                  from their mobile app show up instantly & automatically in
                  PayHero, ready for processing payroll.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoDisc />
                  <br />
                  Always accurate
                </h3>
                <p>
                  Every time payroll and labour laws change, we update PayHero
                  so you’re always fully compliant with tax laws and the
                  Holidays Act. No downloads or time consuming updates for you –
                  just business as usual.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoCalculator />
                  <br />
                  Automated public holiday calculations
                </h3>
                <p>
                  PayHero determines whether a public holiday is an otherwise
                  working day for an employee based on their recent work pattern
                  and calculates their entitlements accordingly.
                </p>
              </Box>
            </Row>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoSettings />
                  <br />
                  Set and forget payroll
                </h3>
                <p>
                  With Payroll AutoPilot, PayHero will run payroll for you.
                  You’ll get an email with all the details so you can approve
                  the pay without having to login.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoHeadset />
                  <br />
                  Support you can rely on
                </h3>
                <p>
                  Our friendly Wellington-based support team is available by
                  phone or email to help you out whenever you need it.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoHappy />
                  <br />
                  Try before you buy
                </h3>
                <p>
                  With a fully featured{" "}
                  <Link to="/signup">free 14 day trial</Link>, you can take
                  PayHero for a risk-free test run before committing.
                </p>
              </Box>
            </Row>
          </Box>
          {/* <Image
            filename="PayHeroHero_Cropped_Tradie.png"
            alt="PayHero"
            maxWidth={1000}
            centerImage
            addShadow
          /> */}
        </Wrapper>
      </Container>
      <CallToAction showSalesButton />
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Trades;
